.main-menu-mobile {
  align-items: center;
  background: $primary;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  visibility: hidden;
  width: 100%;
  &.open {
    height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 20;
    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;
      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(5) {
        animation-delay: 0.55s;
      }

      &:nth-of-type(6) {
        animation-delay: 0.6s;
      }
    }
  }

  ul {
    flex: 0;
    font-family: $font-family-heading;
    font-size: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: block;
      opacity: 0;
      padding: 10px;
      position: relative;
      a {
        color: #000;
        display: block;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    left: 20%;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

.lock-scroll {
  overflow: hidden;
}
