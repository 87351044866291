.list-item-image {
  background-position: center;
  background-size: cover;
  display: block;
}

.list-item-rounded {
  border-radius: 16px;
}

.list-item-image.square {
  height: 360px;
  width: 360px;
}

.list-item-large {
  overflow: hidden;
  width: 100%;
  hr.list-item-bar {
    background-color: $primary;
    height: 4px;
    margin: 0;
    opacity: 1;
    width: 80px;
  }

  hr + p {
    margin-bottom: 20px;
    margin-top: 4px;
  }

  .list-item-content * {
    color: #1f1415;
  }

  .list-item-content h3, .list-item-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin-top: 30px;
  }

  .list-item-content p, .list-item-content ul {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  .list-item-button {
    align-items: center;
    color: $white;
    display: flex;
    flex: none;
    flex-grow: 0;
    font-size: 20px;

    font-weight: 500;
    height: 56px;
    justify-content: center;
    line-height: 32px;
    order: 0;
    text-align: center;
    width: 144px;
  }
}

.sponsor-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.sponsor-content {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.square-center {
  align-items: center;
  height: 360px;
  width: 360px;
  object-fit: contain;
  max-width: 100%;
}
