.header {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  color: $primary;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &.header-absolute {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}

.lock-scroll {
  .header {
    &.header-absolute {
      position: static;
    }
  }
}
