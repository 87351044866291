.logo {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }

  img {
    height: 36px;
    width: 36px;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.logo-mobile {
  display: block;
  padding: 10px 0 10px 0;
  width: 40px;
  @include media-breakpoint-up(sm) {
    display: none;
  }

  img {
    height: auto;
    width: 100%;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}
