$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
);

$container-max-widths: (
  sm: 566px,
  md: 740px,
  lg: 960px,
  xl: 1140px,
);

$grid-gutter-width: 10px;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: 10px,
    2: 20px,
    3: 30px,
    4: 40px,
    5: 50px,
    6: 60px,
    7: 70px,
    8: 80px,
    9: 90px,
    10: 100px,
  ),
  $spacers
);
