.content {
  a {
    text-decoration: underline;
  }

  img {
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 100%;
  }

  strong {
    font-width: bold;
  }

  em {
    font-style: italic;
  }

  p {
    color: $steel;
    font-family: $font-family-base;
    line-height: 1.56;
  }

  h1 {
    font-family: $font-family-base;
    font-size: 34px;
    font-weight: normal;
    line-height: 1.26;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  h2 {
    font-family: $font-family-base;
    font-size: 26px;
    font-weight: normal;
    line-height: 1.4;
    margin-top: 30px;
  }

  h3 {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
    margin-top: 30px;
  }

  ul,
  ol {
    margin-bottom: 20px;
    ul,
    ol {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li {
    color: $steel;
    line-height: 1.56;
    margin-bottom: 5px;
    margin-left: 20px;
  }

  hr {
    border: 0;
    border-bottom: 1px solid $white-offset;
  }

  table {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    th,
    td {
      border-top: 1px solid $white-offset;
      padding: 5px;
      vertical-align: top;
    }

    thead th {
      border-bottom: 1px solid $white-offset;
      font-weight: bold;
      text-align: left;
      vertical-align: bottom;
    }

    tbody + tbody {
      border-top: 1px solid $white-offset;
    }
  }

  blockquote {
    border-left: .25em solid $secondary;
    color: $steel;
    padding: 0 1em;
  }
}
