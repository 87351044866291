
.tab {
  border-bottom: 1px solid $steel;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  position: relative;
  @media (min-width: 768px) {
    margin: 80px 25% 0 25%;
  }

  @media (max-width: 1199.98px) {
    margin: 80px 0 0 0;
  }
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  border: 0;
  border-bottom: 4px solid $white;
  cursor: pointer;
  float: left;
  outline: none;
}

/* Change background color of buttons on hover */
.tab button {
  color: $black;
  display: inline-block;
  font-weight: bold;
  line-height: 2em;
  @media (min-width: 768px) {
    font-size: 16px;
    margin: 0 12px 0 12px;
  }

  @media (max-width: 1199.98px) {
    margin: 0 2px 0 2px;
    font-size: 15px;
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.tab button.active {
  border-bottom: 4px solid $primary;
  color: $primary;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    transition: all 225ms ease-in 0s;
  }
}
