.friend-image {
    line-height: 100px;
}

.sponsor-image>img,
.friend-image>img {
    width: 100%;
    aspect-ratio: 1;
    padding: 0.5em;
    vertical-align: top;
    margin-top: -1.2em;
}

.strip:nth-child(even) {
    background-color: #F5F5F5;
}
