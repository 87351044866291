// Colors
$primary: #af1d25;
$primary-shade-1: #84151c;
$primary-shade-2: #580e12;
$primary-shade-3: #982901;

$secondary: #84151c;
$black: #1f1415;
$white: #fff;
$white-offset: #f5f5f5;
$steel: #949494;
$light-gray: #f5f5f5;
$light-gray-shade-1: #e9e9e9;
$light-gray-shade-2: #d1d1d1;
$dark-gray: #949494;
$light-gold: #E4C796;


// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 10%);
$link-hover-decoration: underline;

// Fonts
$font-family-base: 'Noto Sans SC',
sans-serif,
-apple-system;
$font-family-heading: 'Noto Sans SC',
sans-serif,
-apple-system;

// Footer
$footer-background-color: $black;
$footer-text-color: $white;

// Bootstrap
@import 'bootstrap-variables';
@import 'bootstrap/bootstrap-reboot';
@import 'bootstrap/bootstrap-grid';
@import 'bootstrap/buttons';
@import 'bootstrap/utilities';
@import 'bootstrap/card';
@import 'bootstrap/bootstrap'; // Uncomment this line to import the entire Bootstrap library


// Libraries
@import 'libraries/hamburgers/hamburgers';

// Components
@import 'components/type';
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/logo';
@import 'components/main-menu';
/*
A list item is a block that usually contains an image, a title, and some textual content.
This is designed for lists of sponsors and lists of open positions.
*/
@import 'components/list-item';
@import 'components/card';
@import 'components/main-menu-mobile';
@import 'components/hamburger';
@import 'components/buttons';
@import 'components/call';
@import 'components/title';
@import 'components/tabs';
@import 'components/grid';
@import 'components/paragraph';
@import 'components/content';
@import 'components/intro';
@import 'components/intro-image';
@import 'components/strip';
@import 'components/programme';
@import 'components/special_event';
@import 'components/social';
@import 'components/gala_list_button';
@import 'components/hero';
//TODO(tslmy): PoC
//@import 'components/list-item';

// Pages
@import 'pages/home';

body {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 1.3;
    }
}

#detailed-info {
    color: $white;
    font-size: 1.2em;
    padding: 1.25rem 2rem 1.25rem 0;
    text-decoration: underline;
}

.primary-background {
    background-color: $primary;
    color: $white;
}

.primary-text {
    background-color: $white;
    color: $primary;
}

.section {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 960px;
}

#sidebar>.footnotes>ol>li {
    background: $white;
}

#join {
    display: contents;

    .programme {
        border: #ccc dashed 5px;
        box-shadow: none;

        .programme-image {
            border-radius: 0;
            color: #aaa;
            font-size: 10em;
            line-height: 250px;
            width: auto;
        }
    }
}

#join:hover {
    text-decoration: none;

    .programme {
        background: #eee;
    }
}

img {
    width: 100%;
}

#warning {
    border: 1px solid;
    padding: 0.5em;
}

.event {
    background-image: url('https://blog.zgzg.io/wp-content/uploads/circel.png'), url('http://blog.zgzg.io/wp-content/uploads/line2.png');
    background-position-x: 0, 12px;
    background-position-y: 0, 30px;
    background-repeat: no-repeat, no-repeat;
    margin-bottom: 15px;
    padding-left: 25px;
}

.event-metadata h2 {
    margin-top: 0;
}

.event img {
    margin: 0;
}

.event li {
    margin-bottom: 10px;
    margin-top: 10px;
}

#ticket-banner,
#tour-banner,
#ybyf-banner {
    background-color: rgba(193, 32, 40, 255) !important;
    background-position: left 0 center;
    color: $white;
}

#prize {
    margin: 20px 0 0 20px;
    width: 200px !important;
}

[id^="eventbrite-widget-container"] iframe {
    height: 100%;
}

#galalist-space {
    margin: 60px 0
}

#galalist-pic-beginning {
    margin: 0 0 40px 0
}

.galalist-pic {
    @media (min-width: 768px) {
        background-position: center;
        background-size: cover;
        display: inline-block;
        height: 170px;
        width: 170px;
    }

    @media (max-width: 1199.98px) {
        background-position: center;
        background-size: cover;
        display: inline-block;
        height: 132px;
        width: 132px;
    }
}

@media screen and (min-width: 768px) {

    #ticket-banner .container {
        background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1640497467/girls_sue1pz.png');
        background-position: right;
        background-repeat: no-repeat;
        margin: 0 auto 0 auto;
        max-width: 1100px;
        min-height: 420px;
        padding-bottom: 5px;
        padding-top: 40px;
    }

    #tour-banner .container {
        background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1639618313/zgzg-io-website/2022%E6%98%A5%E6%99%9A%E6%8B%8D%E6%91%84%E8%8A%B1%E7%B5%AE/fairshow_rw4bsj.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto 0 auto;
        max-width: 1100px;
        min-height: 450px;
        padding-top: 45px;
    }

    #ybyf-banner .container {
        background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1659927588/zgzg-io-website/%E4%BA%91%E9%9B%86/YBYF/ybyf_f6kqk8.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto 0 auto;
        max-width: 1100px;
        min-height: 450px;
        padding-top: 45px;
    }

    #ticket-poster,
    #tour-map {
        padding: 0 20px 30px;
    }

    #tour-map {
        max-width: 1140px;
    }

    #tour-div {
        text-align: left;
    }

    #cloth-div {
        margin: 0 160px;
    }

    iframe#cloth {
        height: 460px;
        width: 800px;
    }

    #last-row-partners {
        padding: 30px;
    }
}

#ticket-button-gala {
    margin: 40px auto 0;
    width: 50%;
}

@media (max-width: 767px) {
    #cloth-div {
        height: 260px;
    }

    iframe#cloth {
        height: 260px;
        width: 100%;
    }

    h1 {
        margin-top: 30px;
    }

    #ticket-banner.container {
        background-size: 120%;
        margin: 0 auto 0 auto;
        min-height: 380px;
        padding-top: 35px;
    }

    #tour-banner.container {
        background-size: 120%;
        margin: 0 auto 0 auto;
        min-height: 400px;
        padding-top: 35px;
    }

    #ticket-poster {
        padding: 100px 1px 40px;
    }

    #tour-map {
        padding: 0 5px 20px;
    }

    #last-row-partners {
        padding: 20px;
    }
}

#ticket-banner p {
    color: $white;
}

#tour-banner p {
    color: $white;
}

#tour-banner p {
    color: $white;
}

#ticket-banner img {
    width: 80px;
}

#ticket-banner #red-packet {
    height: 80px;
    width: 55px;
}

img#red-packet {
    border: 1px solid #fff;
}

.float-left.mr-1 {
    margin-top: 5px;
}

#yj-img {
    width: 130px
}

iframe.monthly-src {
    display: block;
    height: 100%;
    margin: auto;
    width: 1000px;
}

// 春晚页面
h2.gala-heading {
    margin: 30px 0 20px 0;
}

#gala-banner {
    background-color: rgba(193, 32, 40, 255) !important;
    background-position: left 0 center;
    color: $white;
}

#gala-banner .container {
    background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1637381379/zgzg-io-website/front-page-1280x712_ueloj5.png');
    background-position: -150px;
    background-repeat: no-repeat;
    margin: 0 auto 0 auto;
    min-height: 550px;
    padding-top: 50px;
}

#gala-banner p {
    color: $white;
}

#singer-banner {
    background-color: rgba(193, 32, 40, 255) !important;
    background-position: left 0 center;
    color: $white;
}

#singer-banner .container {
    background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1655094802/signer-backgroud_qd6cze.png');
    background-position: -150px;
    background-repeat: no-repeat;
    margin: 0 auto 0 auto;
    min-height: 550px;
    padding-top: 50px;
}

#singer-banner p {
    color: $white;
}

.sponsorship-image {
  background-image:url(https://res.cloudinary.com/zaigezaigu/image/upload/v1670371900/zgzg-io-website/%E8%B5%9E%E5%8A%A9/jason-goodman-wVh5grSMYaY-unsplash_bupoj8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto 0 auto;
  min-width: 100%;
  padding-top: 50px;
}

.sponsor-body {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: black;
}

.sponsor img {
    max-height: 400px;
    object-fit: contain;
    width: 90%;
}

.rainbow-letters {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: #ff0000;
    background: -webkit-linear-gradient(left, #ffa500, #ffff00, #008000);
    background: -o-linear-gradient(right, #ffa500, #ffff00, #008000);
    background: -moz-linear-gradient(right, #ffa500, #ffff00, #008000);
    background: linear-gradient(to right, #ffa500, #ffff00, #008000);
    font-size: medium;
    font-weight: bold;
}

#ticket-sell {
    font-size: medium;
}

#eventbrite {
    color: $primary;
}

.title-font {
    color: $primary;
}

#faq p:nth-child(odd) {
    font-weight: bold;
    color: black;
}

#faq p:nth-child(even) {
    margin-top: -1rem !important;
}

#eb-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    /* Sit on top of the page content */
    bottom: 0;
    /* Hidden by default */
    cursor: pointer;
    /* Full width (cover the whole page) */
    display: none;
    /* Full height (cover the whole page) */
    height: 100%;
    left: 0;
    padding: 1vh 1vw 1vh 1vw;
    position: fixed;
    right: 0;
    top: 0;
    /* Black background with opacity */
    width: 100%;
    /* Specify a stack order in case you're using a different order for other elements */
    z-index: 50;
    /* Add a pointer on hover */
}


#close-overlay {
    background-color: $white;
    border: 1px solid #808080;
    border-radius: 50%;
    height: 3vw;
    min-height: 40px;
    min-width: 40px;
    position: absolute;
    right: 1vw;
    top: 1vw;
    width: 3vw;
    z-index: 100;
}

.sponsor-gala-combined img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80%;
}

p.black-p {
    color: black;
}

.video {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 700px;
}

#ytCarousel {
    margin: 0 auto;
    @media screen and (max-width: 760px) {
        height: 50vh;    
        width: 80%;
    }
    width: 100%;
}

.carousel-card {
    @media screen and (max-width: 760px) {
        height: 50vh;    
    }
    border-radius: 10px; 
    overflow: hidden; 
    background-color: #F5F5F5; 
    height: 70vh;
}

.carousel-item > iframe {
    width: 100%;
    height: 90%;
}

.carousel-item > div {
    width: 100%;
    height: 10%;
    color: black;
    font-weight: 600;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-bg-gold {
    background-color: $light-gold;
}

.banner-text-red {
    color: $primary-shade-3;
}

.btn-bg-darkened {
    @include button-variant($primary,$primary);
}

.btn-width-wide {
    width: calc(240px + 0.5rem);
    @media screen and (max-width: 990px){
        min-width: 120px;
        max-width: 120px;
    }
}

.btn > span {
    vertical-align: middle;
}

.gala-bg{
    background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736054382/2025%E8%9B%87%E5%B9%B4%E6%98%A5%E6%99%9A%E7%BD%91%E9%A1%B5banner-1230_pktphi1_ew7yqx.png');
  
    // @media screen and (max-width: 768px) {
    //     background: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736201689/web_poster_0106_jujrnp.jpg');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position: center;  
    // }
    
    @media screen and (min-width: 769px) and (max-width: 1800px) {
        background: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736054196/2025%E8%9B%87%E5%B9%B4%E6%98%A5%E6%99%9A%E7%BD%91%E9%A1%B5banner-1230_pktphi2_nhiyy2.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
      
    
}

.sponsorship-bg{
    background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1702772359/%E8%B5%9E%E5%8A%A9%E9%A2%98%E5%9B%BE_fb6mny.jpg');
}

a.disabled {
    pointer-events: none;
    cursor: default;
    background-color: #F5F5F5 !important;
    color: #949494 !important;
    border: none !important;

}

#eventInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > :nth-child(1) {
        text-align: start;
    }

    & > :nth-child(2) {
        padding-top: 100px;
    }
    @media screen and (max-width: 992px) {
        flex-direction: column;
        & > :nth-child(1){
            width: 100%;
            text-align: center;
        }
        & > :nth-child(2) {
            padding-top: 0px !important;
            & > h2 {
                display: none;
            }
        }
        
    }
}


@media screen and (max-width: 992px) {
#mainSponsor {
    flex-direction: column !important;
}
#mainSponsor > div {
    width: 100% !important;
}
}
