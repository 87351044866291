.main-menu {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }

  > ul {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    > li {
      font-size: 1rem;
      list-style: none;
      > a:not(.button) {
        color: $black;
        display: inline-block;
        font-weight: normal;
        line-height: 2em;
        margin: 10px 12px 10px 12px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        > a:not(.button) {
          border-bottom: 4px solid $primary;
          color: $primary;
          font-weight: bold;
          margin-bottom: 6px;
          text-decoration: none;
          &:hover {
            text-decoration: none;
            transition: all 225ms ease-in 0s;
          }
        }
      }
    }
  }
}
