.intro {
  background-image: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736054382/2025%E8%9B%87%E5%B9%B4%E6%98%A5%E6%99%9A%E7%BD%91%E9%A1%B5banner-1230_pktphi1_ew7yqx.png');
  @media screen and (max-width: 760px) {
    background: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736201689/web_poster_0106_jujrnp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;  
  }

  @media screen and (min-width: 761px) and (max-width: 1800px) {
    background: url('https://res.cloudinary.com/zaigezaigu/image/upload/v1736054196/2025%E8%9B%87%E5%B9%B4%E6%98%A5%E6%99%9A%E7%BD%91%E9%A1%B5banner-1230_pktphi2_nhiyy2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
    
  min-height: 90vh;

  background-position: center;
  background-size: cover;
  color: $white;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  h2 {
    font-family: $font-family-base;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 12px;
  }

  p {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 26px;
  }

  #content {
    background: $primary;
    opacity: .9
  }
}

.home-section-header {
  font-size: 20px;
  font-style: normal;
  text-align: center;

  h2 {
    font-size: 36px;
    font-weight: 700;
  }

  .divider {
    margin: auto;
    width: 80px;
  }

  hr {
    background-color: $primary;
    height: 4px;
    margin: auto;
    opacity: 1;
    width: 80px;
  }

  p {
    color: black;
    line-height: 32px;
  }

  .header-color-darkenred {
    color: darken($primary, 10%);
  }

  .custom-text-style {
    .b {
      font-weight: 5000;
    }
    color: darken($primary, 10%);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }

  .home-section-content {
    margin: auto;
    width: 60%;
  }

  .home-section-content_top {
    margin: auto;
    width: 80%;
    font-size: 20px;
    .row {
      .col {
        width: 60%
      }
      .col_2 {
        width: 40%
      }
    }
  }

  @media (max-width: 720px) {
    .home-section-content_top {
      width: 80vw; /*this makes the container take 80% of the viewport's width*/
    }
  }
}

.pb-large {
  margin-bottom: 120px;
  margin-top: 120px;
}

.pb-small {
  margin-top: 120px;
}

// only use for 2023 Singing Contest home page info, will remove when event end
.content-singer-background {
  background-image: linear-gradient(#4c6c36, #263b17)!important; // overrides #content background color
}
