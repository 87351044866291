.hamburger {
  cursor: pointer;
  outline: none;
  padding: 10px 0 10px 10px;
  z-index: 30;
  @include media-breakpoint-up(md) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $primary;
  }

  .hamburger-inner::after {
    right: 0;
    width: 18px;
  }

  &.is-active {
    .hamburger-inner::after {
      right: unset;
      width: inherit;
    }
  }
}
