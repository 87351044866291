.social {
  display: block;
  img {
    height: 20px;
    width: 20px;
  }

  a {
    display: inline-block;
    padding: 10px;
    &:hover {
      opacity: 0.8;
    }
  }
}
