.special_event {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-align: left;
  .special_event-image {
    display: flex;
    height: 250px;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  img {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .special_event-title {
    color: #555;
    margin-bottom: 20px;
    a:hover {
      text-decoration: none;
    }
  }

  .special_event-description, .special_event-contact, .special_event-highlights {
    display: inline-block;
    width: 100%;
  }

  .special_event-contact {
    background-color: $white-offset;
    box-sizing: border-box;
    list-style-position: inside;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .special_event-highlights {
    box-sizing: border-box;
    list-style-position: inside;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
