// .button is inherited from Serif, and will be deprecated in the new design
.button {
  -webkit-transition: all 0.15s ease;
  background: $primary;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  letter-spacing: 0.025em;
  line-height: 40px;
  padding: 0 14px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.15s ease;
  white-space: nowrap;
  &:hover {
    background-color: lighten($primary, 10%);
    color: #fff;
    text-decoration: none;
    //box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }
}

.button_r {
  -webkit-transition: all 0.15s ease;
  background: $primary;
  border-radius: 4px;
  border: 1px solid $primary;
  color: #fff;
  display: inline-block;
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 20px;
  font-weight: normal;
  height: 50px;
  min-width: 130px;

  letter-spacing: 0.025em;
  line-height: 40px;
  padding: 0 14px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.15s ease;
  white-space: nowrap;
  margin: 5px 15px 5px 15px;
  &:hover {
    background-color: lighten($primary, 10%);
    color: #fff;
    text-decoration: none;
    //box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }
  @media screen and (max-width: 760px) {
    width: 273.8px;
    
  }
}

.button_w {
  -webkit-transition: all 0.15s ease;
  background: #fff;
  border-radius: 5px;
  border: 1px solid $primary;
  color: $primary;
  display: inline-block;
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 20px;
  font-weight: normal;
  height: 50px;
  min-width: 130px;
  letter-spacing: 0.025em;
  line-height: 40px;
  padding: 0 14px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.15s ease;
  white-space: nowrap;
  margin: 5px 15px 5px 15px;
  &:hover {
    background-color: darken(#fff, 10%);
    color: $primary;
    text-decoration: none;
    //box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }
  @media screen and (max-width: 760px) {
    width: 273.8px;
    
  }
}

.btn:disabled {
  border: 0;
}

.btn-primary {
  background: $primary;
  border-radius: 4px;
  color: $white;
  &:hover {
    background-color: $primary-shade-1;
  }
  :not(.btn-check) + &:active,  // overrides bootstrap defaults in bootstrap/_buttons.scss L82
  &:active {
    background-color: $primary-shade-2;
  }

  &:disabled {
    background-color: $light-gray;
    color: $dark-gray;
  }
}

.btn-secondary {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
  &:hover {
    background-color: $light-gray-shade-1;
    // color and border properties are hardcoded again to avoid default transformation on border state.
    border: 1px solid $primary;
    color: $primary;
  }

  :not(.btn-check) + &:active,
  &:active {
    background-color: $light-gray-shade-2;
    border: 1px solid $primary;
    color: $primary;
  }

  &:disabled {
    background-color: $light-gray;
    color: $dark-gray;
  }
}

.btn-tertiary {
  border: 0;
  color: $primary;
  text-decoration: underline;
  &:hover {
    border: 0;
    color: $primary-shade-1;
    text-decoration: underline;
  }

  :not(.btn-check) + &:active,
  &:active {
    border: 0;
    color: $primary-shade-2;
    text-decoration: underline;
  }

  &:disabled {
    color: $dark-gray;
    text-decoration: underline;
  }
}

.btn-lg {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.btn-light {
  background: $white;
  border: 0;
  color: $primary;
  &:hover {
    background-color: $light-gray-shade-1;
    border: 0;
    color: $primary;
  }
  :not(.btn-check) + &:active,  // overrides bootstrap defaults in bootstrap/_buttons.scss L82
  &:active {
    background-color: $light-gray-shade-2;
    border: 0;
    color: $primary;
  }

  &:disabled {
    background-color: $light-gray;
    border: 0;
    color: $dark-gray;
  }
}

// only use for 2023 Singing Contest home page info, will remove when event end
.btn-singer {
  color: #0c4128;
}

