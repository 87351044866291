.programme {
  align-items: center;
  background-color: $white-offset;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-bottom: 20px;
  text-align: left;
  .programme-image {
    display: flex;
    height: 250px;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  img {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .programme-title {
    color: $black;
    margin-bottom: 20px;
    text-align: center;
  }

  .programme-description,
  .programme-title,.programme-contact,
  .programme-highlights {
    display: inline-block;
    padding: 0 30px;
    width: 100%;
  }

  .programme-contact {
    background-color: $white-offset;
    box-sizing: border-box;
    list-style-position: inside;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .programme-highlights {
    box-sizing: border-box;
    list-style-position: inside;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

#concluded-programmes .programme {
  .programme-title {
    color: #888;
  }
}

#more-programmes {
  display: flex;
  margin: 20px auto 20px auto;
  width: fit-content;
}
