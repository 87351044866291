a {
  color: $primary;
}

p {
  color: $steel;
  font-family: $font-family-base;
  line-height: 26px;
  margin-bottom: 20px;
}

h1 {
  font-family: $font-family-heading;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 1.2;
  }
}

h2 {
  font-family: $font-family-heading;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    font-size: 26px;
    line-height: 1.4;
  }
}

h3 {
  font-size: 16px;
  line-height: 1.4;
  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 1.4;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  li {
    list-style-position: inside;
    margin: 0;
    padding: 0;
  }
}
