.gala-list-wrapper {
  display: flex;
  //margin: 50px auto;
  //width: 800px;
  //overflow: hidden;
  //border-radius: 8px;
  //padding: 15px ;
  //box-shadow: 20px 20px 60px #bebebe,
  //-20px -20px 60px #ffffff;
}

.gala-list-text {
  font-size: 16px;
  color: #949494;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  /* display: flex; */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  position: relative;
}

.gala-list-text::before {
  content: '';
  height: calc(100% - 19px);
  float: right;
}

.gala-list-text::after {
  content: '';
  width: 999vw;
  height: 999vw;
  position: absolute;
  box-shadow: inset calc(100px - 999vw) calc(80px - 999vw) 0 0 #fff;
  margin-left: -110px;
}

.gala-list-btn{
  float: right;
  clear: both;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  color:  $primary;
  text-decoration: underline;
  cursor: pointer;
}

.gala-list-btn::before{
  content:'展开'
}

.gala-list-exp{
  display: none;
}

.gala-list-exp:checked+.gala-list-text{
  -webkit-line-clamp: 999;
}

.gala-list-exp:checked+.gala-list-text::after{
  visibility: hidden;
}

.gala-list-exp:checked+.gala-list-text .gala-list-btn::before{
  content:'收起'
}
