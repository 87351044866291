.row.gala-text-grid {
  margin-left: auto;
  margin-right: auto;
}

.col.gala-text-grid {
  @media screen and (max-width: 540px) {
    font-size: 24px;
  }
  color: $primary;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.col.gala-text-grid-content {
  color: $primary;
  font-weight: bold;
  text-align: center;
}

.row.gala-photo-grid {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.col.gala-photo-grid {
  height: 20%;
  margin-right: 20px;
  width: 30%;
}

.show-signup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.row.icon-grid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.row.button-grid {
  margin-left: 0;
  margin-right: 0;
  @media (min-width: 768px) {
    width: 45%;
  }
  @media (max-width: 1199.98px) {
    width: 110%;
  }
}

.col.button-grid {
    margin-left: 0;
    margin-right: 0;
}

.col.icon-grid-header {
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 60px;
}

.col.icon-grid-text-header {
  color: $primary;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.col.icon-grid-text-bold {
  color: $black;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.col.icon-grid {
  color: $black;
  font-size: 20px;
  text-align: center;
}

.show-type {
  color: $primary;
}

.wrapper_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  //grid-auto-rows: minmax(100px, auto);
}
.one {
  grid-column: 1;
  grid-row-start: span 2;
}
.two {
  .h3 {
    font-weight: bold;
  }
  grid-column: 2;
  grid-row: 1;
}
.three {
  grid-column: 2;
  grid-row: 2;
}
